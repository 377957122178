import { SelectCustomerProduct } from '@/views/dialogs';
import { customerPointService, customerShopService, pointTagService } from '@/api';
import { PointTagResource, CustomerProductResource, ShopTagResource, CustomerPointResource } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  components: { SelectCustomerProduct }
})
export default class AddCustomerPoint extends mixins(DialogMixin) {
  @Prop({ required: false, type: Number }) public pointId?: number;

  public pointForm: Partial<CustomerPointResource & { companyId: number }> = {
    name: '',
    floor: '',
    finishHeight: undefined,
    finishWidth: undefined,
    visibleHeight: undefined,
    visibleWidth: undefined,
    pointTagList: [],
    productId: undefined,
    productName: '',
    shopId: undefined,
    remark: ''
  };

  public resourceFormRules: { [P in keyof Partial<CustomerPointResource>]: Array<Object> } = {
    name: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerPoint.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerPointService
            .checkPointName(value, Number(this.$route.query.companyId), this.pointId)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    shopId: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerPoint.selectShop')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    productName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerPoint.selectCustomerProduct')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    visibleWidth: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('customerPoint.inputVisibleWidth')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    visibleHeight: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value || value === 0) {
            callback(new Error(translation('customerPoint.inputVisibleWidth')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    finishWidth: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerPoint.inputFinishWidth')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    finishHeight: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerPoint.inputFinishHeight')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public pointTags: Array<PointTagResource> = [];

  public productDialogVisible = false;

  public shops: NormalSelectOptions = [];

  private operationType: 'add' | 'edit' = 'add';

  public async created(): Promise<void> {
    this.pointForm.companyId = Number(this.$route.query.companyId);
    this.getPointTags();
    this.getShops();
    if (!this.pointId) {
      this.operationType = 'add';
      this.title = 'customerPoint.addPoint';
      return;
    }
    this.operationType = 'edit';
    this.title = 'customerPoint.editPoint';
    const point = await customerPointService.getById(this.pointId);
    Object.assign(this.pointForm, point);
  }

  public destroyed(): void {
    this.$emit('dialog-closed');
  }

  public onSubmit(): void {
    (this.$refs.pointForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        if (this.operationType === 'add') {
          await customerPointService.post(this.pointForm as CustomerPointResource);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await customerPointService.put(this.pointForm as CustomerPointResource);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', this.pointForm);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public tagChecked(tag: ShopTagResource, tagValue: string): void {
    const findIndex = this.pointForm.pointTagList!.findIndex(x => x.tagId === tag.id && x.value === tagValue);
    if (findIndex !== -1) {
      this.pointForm.pointTagList!.splice(findIndex, 1);
      return;
    }
    this.pointForm.pointTagList!.push({
      tagId: tag.id,
      tagName: tag.name,
      value: tagValue,
      pointId: this.pointForm.id
    });
  }

  public selectCustomerProduct(): void {
    this.productDialogVisible = true;
  }

  public handleSelectedProduct(product: CustomerProductResource): void {
    this.pointForm.productName = product.productName;
    this.pointForm.productId = product.id;
  }

  private getEffect(tagId: number, tagValue: string): string {
    const findIndex = this.pointForm.pointTagList?.findIndex(x => x.tagId === tagId && x.value === tagValue);
    return findIndex === -1 ? 'plain' : 'dark';
  }

  private getPointTags(): void {
    pointTagService
      .getList({ companyId: Number(this.$route.query.companyId) } as any)
      .then(res => {
        this.pointTags = res.data;
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getShops(): void {
    customerShopService
      .getAllShop(Number(this.$route.query.companyId))
      .then(res => {
        this.shops = res.map(x => {
          return {
            label: x.shopName,
            value: x.id
          };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
