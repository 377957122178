import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ImportRes, CustomerPointResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  convertProvinces,
  showWarningConfirm,
  translation,
  messageError
} from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, ResourceStatusEnum } from '@/resource/enum';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
import { customerPointService, customerShopService } from '@/api';
import AddCustomerPoint from './add-customer-point/add-customer-point.vue';
@Component({
  name: 'CustomerPoint',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, ImportFile, AddCustomerPoint }
})
export default class CustomerPoint extends Vue {
  public tableOption: OsTableOption<CustomerPointResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 分类导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.pointDatacheck;

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerPointResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: 'left'
    },
    {
      prop: 'shopName',
      label: 'customerPoint.shopName',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'customerPoint.code',
      minWidth: '150px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'name',
      label: 'customerPoint.name',
      showOverflowTooltip: true,
      minWidth: '150px',
      fixed: true
    },
    {
      prop: 'visibleHeight',
      label: 'customerPoint.visibleSize',
      minWidth: '220px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${(row as CustomerPointResource).visibleWidth} * 
        ${(row as CustomerPointResource).visibleHeight}
        `;
      }
    },
    {
      prop: 'finishHeight',
      label: 'customerPoint.finishSize',
      minWidth: '220px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${(row as CustomerPointResource).finishWidth} * 
        ${(row as CustomerPointResource).finishHeight}
        `;
      }
    },
    {
      prop: 'productName',
      label: 'customerPoint.product',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'floor',
      label: 'customerPoint.floor',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '200px'
    },
    {
      prop: 'status',
      label: 'common.status',
      showOverflowTooltip: true,
      minWidth: '100px'
    },

    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Slot',
      slotName: 'address',
      field: 'provinces',
      label: 'customer.area',
      option: {}
    },
    {
      type: 'Select',
      field: 'shopId',
      label: 'customerPoint.shopName',
      option: {
        placeholder: 'customerPoint.selectShop',
        filterable: true,
        clearable: true
      },
      optionData: []
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:point:save',
      handleClick: (): void => {
        this.openPointDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:point:import',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'customerPoint.dataCheck',
      operationType: 'importDataCheck',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:point:importDataCheck',
      handleClick: (): void => {
        this.openDataCheckFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:point:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeletePoint();
      }
    },
    {
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'customer:point:exportData',
      loading: false,
      handleClick: (): void => {
        this.exportData();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'customer:point:updateStatus',
      handleClick: (): void => {
        this.batchUpdatePointStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'customer:point:updateStatus',
      handleClick: (): void => {
        this.batchUpdatePointStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerPointResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:point:edit',
        handleClick: (item: CustomerPointResource): void => {
          this.openPointDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:point:delete',
        handleClick: (item: CustomerPointResource): void => {
          this.deletePoint(item);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;
  public dataCheckDialogVisible = false;
  public importTitle = 'dialog.importCustomerPoint';
  public dataCheckTitle = 'dialog.importCustomerPointCheckData';
  public pointId: number | null = null;
  public dialogVisible = false;
  public totalData = 0;
  public queryForm: Partial<{
    provinces?: Array<string>;
    keywords?: string;
    shopId?: number | null;
    companyId: number;
  }> = {
    keywords: '',
    shopId: null,
    provinces: []
  };
  private selectedRows: Array<CustomerPointResource> = [];

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.getShops();
      this.reloadData();
    }
  }

  public created(): void {
    this.getShops();
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.pointId = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.pointTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerPointResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: CustomerPointResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public importSuccess(path: string): void {
    customerPointService
      .importData(path, this.queryForm.companyId!)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }
  public dataCheckimportSuccess(path: string): void {
    customerPointService
      .importDataCheck(path, this.queryForm.companyId!)
      .then(() => {
        Message.success(translation('customerPoint.noAbnormalData'));
        this.dataCheckDialogVisible = false;
      })
      .catch(error => {
        (this.$refs.dataCheckDialog as ImportFile).setLoading(false);
        handleImportError(error);
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    customerPointService
      .downloadTemplate(this.queryForm.companyId!)
      .then(blob => {
        downloadFileByBlob('customer_point_template.xlsx', blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  private async exportData(): Promise<void> {
    const exportBtn = this.operationOptions.find(x => x.operationType === 'export');
    exportBtn!.loading = true;
    try {
      const exportQuery = {
        ...convertProvinces(this.queryForm.provinces || []),
        ...this.queryForm,
        pointIdList: this.selectedRows.map(x => x.id)
      } as any;
      const blob = await customerPointService.exportData(exportQuery);
      downloadFileByBlob(translation('customerPoint.exportFileName'), blob);
    } catch (error) {
      messageError(error);
    } finally {
      exportBtn!.loading = false;
    }
  }

  private openPointDialog(data: CustomerPointResource | null = null): void {
    if (data) {
      this.pointId = data.id;
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerPointResource>): void {
    this.operationOptions.forEach(x => {
      if (
        x.operationType !== 'add' &&
        x.operationType !== 'import' &&
        x.operationType !== 'export' &&
        x.operationType !== 'importDataCheck'
      ) {
        x.disabled = value.length === 0;
      }
    });
  }

  private deletePoint(data: CustomerPointResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerPointService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeletePoint(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerPointService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    const query = { ...this.queryForm, ...convertProvinces(this.queryForm.provinces || []) } as any;
    customerPointService
      .getList(query, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private batchUpdatePointStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    customerPointService
      .batchUpdatePointStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getShops(): void {
    customerShopService
      .getAllShop(this.companyId!)
      .then(res => {
        const shopQuery = this.queryItemOptions.find(x => x.field === 'shopId');
        const shopOptions = res.map(x => {
          return {
            label: x.shopName,
            value: x.id
          };
        });
        shopQuery!.optionData = shopOptions || [];
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
  private openDataCheckFileDialog(): void {
    this.dataCheckDialogVisible = true;
  }
}
